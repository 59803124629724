const regEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const regMobileNumber = /^1[3-9]\d{9}$/;

export const isValidateEmail = (emailAddress) => {
  const res = regEmail.test(emailAddress);
  return res;
};

export const isValidateMobileNumber = (mobileNumber) => {
  const res = regMobileNumber.test(mobileNumber);
  return res;
}

