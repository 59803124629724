import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import querystring from 'query-string';
import { Container } from 'react-bootstrap';

import { containerScreenStyle, logoStyle } from '../../styles/shared';

import Logo from '../Logo';
import FormTicket from '../FormTicket';
import HelpError from '../HelpError';
import i18n from '../../i18n.js';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameEnabled: false,
      email: '',
      lang: '',
      mobile: '',
      name: '',
      openId: '',
      uid: '',
      spinner: false,
    };
  }

  componentDidMount() {
    const qs = querystring.parse(document.location.hash.split('?')[1]);
    let lang = qs.lang ? qs.lang : 'en';
    if (!qs.openId) {
      document.location.href = '#/error?type=share_error&lang=' + lang;
      return;
    }
    this.setState({
      email: qs.email,
      lang: qs.lang,
      mobile: qs.mobile,
      name: qs.name,
      openId: qs.openId,
      uid: qs.uid
    });

    i18n.changeLanguage(lang).then();

    if (!qs.name) {
      this.setState({ nameEnabled: true });
    }
    
    document.location.href = '#/help';
    return;
  }

  render() {
    return (
      <Container style={containerScreenStyle} fluid='md'>
        <div style={logoStyle}>
          <Logo />
        </div>

        <div id='container'>
          <FormTicket
            email={this.state.email}
            mobile={this.state.mobile}
            name={this.state.name}
            openId={this.state.openId}
            uid={this.state.uid}
            nameEnabled={this.state.nameEnabled}
            lang={this.state.lang}
            spinner={this.state.spinner}
            onSubmit={() => {
              this.setState({
                raiseResult: null,
                raiseError: null,
                spinner: true,
              });
            }}
            onProgress={() => {
              this.setState({
                raiseError: null,
              });
            }}
            onSuccess={(data) => {
              this.setState({
                raiseResult: data,
              });
              this.props.history.push('/help/success');
            }}
            onError={(error) => {
              this.setState({
                raiseError: error,
                spinner: false,
              });
            }}
          />
          <HelpError error={{ data: this.state.raiseError }} />
        </div>
      </Container>
    );
  }
}

Component.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  nameEnabled: PropTypes.bool,
  email: PropTypes.string,
  lang: PropTypes.string,
  mobile: PropTypes.string,
  name: PropTypes.string,
  openId: PropTypes.string,
  uid: PropTypes.string,
  spinner: PropTypes.bool,
};

export default withRouter(Component);
