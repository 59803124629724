import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';

import I18n from '../I18n';
import querystring from 'query-string';
import i18n from '../../i18n';

import {textStyle, containerScreenStyle, logoStyle, iconStyle} from '../../styles/shared';
import Logo from '../Logo'




class Component extends React.Component  {

    componentDidMount() {
        const qs = querystring.parse(document.location.hash.split('?')[1]);
        let lang = qs.lang ? qs.lang : 'en' ;
        i18n.changeLanguage(lang).then()
    }

    render() {
        return (
            <Container style={containerScreenStyle} fluid='md'>
                <div style={logoStyle}>
                    <Logo />
                </div>
                <Row>
                    <Col xs={12} style={iconStyle} >
                        <i className='icofont-calendar'></i>
                    </Col>
                </Row>

                <div id='container'>
                    <div style={textStyle}>
                        <I18n code='acknowledage_cancel_update_message' />
                    </div>
                </div>

            </Container>
        );
    }
}

export default Component;