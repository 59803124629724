
import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Container, Row, Col } from 'react-bootstrap';
import { Button, Select } from 'react-weui';

import ClientApi from '../../services/clientApi';
import { buttonStyle, colStyle, containerStyle, inputStyle, textStyle } from '../../styles/shared';

import I18n from '../I18n';

class Component extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      mobile: '',
      country: '+86',

      time: 0,
      isOn: false,
      start: 0,

      timeout: 90 * 1000 // button timeout milisec
    };

    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    this.setState({
      isOn: true,
      time: this.state.time,
      start: Date.now() - this.state.time
    })

    this.timer = setInterval(() => {
      this.setState({
        time: Date.now() - this.state.start,
        timerButton: `${Math.round((this.state.timeout -this.state.time)/1000)}s`
      });

      if (this.state.time > this.state.timeout) {
        this.stopTimer();
        this.props.onTimeOut && this.props.onTimeOut();
      }

    }, 100);
  }

  stopTimer() {
    clearInterval(this.timer);
    this.setState({time: 0, isOn: false})
  }

  resetTimer() {
    this.setState({time: 0, isOn: false})
  }

  mobileChange(e) {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^0-9]/g,'');
    this.setState({mobile: newValue});
    this.props.onProgress && this.props.onProgress( );
    this.stopTimer(); 
  }

  countryChange(e) {
    this.setState({country: e.target.value});
    this.props.onProgress && this.props.onProgress( );
    this.stopTimer(); 
  }

  async pinRequest(e) {
    e.preventDefault();

    try {

      this.props.onSubmit && this.props.onSubmit( { mobile: this.state.mobile });
  
      this.stopTimer(); 

      const client = new ClientApi( this.props.lang );
      const res = await client.pin( this.state.country + this.state.mobile, this.props.oauthCode );

      if (res.pinId) {
        this.setState( { pinId: res.pinId } );

        this.startTimer();
        this.props.onSuccess && this.props.onSuccess( { pinId: res.pinId });
        return;
      }

      throw new Error('request_invalid');

    } catch(error) {

      this.stopTimer();
      this.props.onError && this.props.onError( error );
    }
  }

  isValidNumber() {
    return typeof this.state.mobile === 'string' && (this.state.country === '+86' && this.state.mobile.length === 11);
  }

  render() {

    return (
      <Container style={containerStyle}>

      <Row>

        <Col xs={12} style={colStyle}>
          <div style={textStyle}><I18n code='home_message' /></div>
        </Col>
      </Row>

      <Row>
        <Col xs={12 } style={colStyle}>
          <div style={{ paddingTop: '20px', paddingBottom: '0px'}}></div>
        </Col>
      </Row>

      <Row>
        <Col xs={12 } style={colStyle}>
          <div style={textStyle}><I18n code='mobile_number' /></div>
        </Col>
      </Row>

      <Row>
        <Col xs={3} style={colStyle}>
          <Select style={inputStyle} className='weui-select' id='country_select' onChange={(e) => this.countryChange(e)} value={this.state.country}>
            <option value='+86'>+86</option>
          </Select>
        </Col>

        <Col style={colStyle}>
          <input disabled={this.state.isOn} style={inputStyle} id='mobile_input' className='weui-input' autoFocus type='text' placeholder={this.props.t('mobile_placeholder')} value={this.state.mobile} onChange={(e) => this.mobileChange(e)} maxLength='11' />
        </Col>
        
        <Col xs={3} className='justify-content-end' style={colStyle}>
          <Button disabled={this.state.isOn || !this.isValidNumber()} id='pin_request_button' style={buttonStyle} onClick={(e) => this.pinRequest(e)}>{ (this.state.isOn ? (<div>{this.state.timerButton}</div>) : (<div><I18n code='get_pin' /></div>))}</Button>
        </Col>
      </Row>

      </Container>
    );
  }
}

Component.propTypes = {
  oauthCode: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onTimeOut: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(Component);
