import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

class Component extends React.Component  {

  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.t(this.props.code) }}></div>
    );
  }
}

Component.propTypes = {
  t: PropTypes.func,
  code: PropTypes.string,
};


export default withTranslation()(Component);