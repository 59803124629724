import React, { Component } from 'react';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withTranslation } from 'react-i18next';
import BindScreen from './components/BindScreen';
import BindSuccess from './components/BindSuccess';
import ErrorScreen from './components/ErrorScreen';
import Help from './components/Help';
import HelpSuccess from './components/HelpSuccess';
import ConsultationAcknowledgeSuccess from './components/ConsultationAcknowledgeSuccess';
import ConsultationChange from './components/ConsultationChange';

class App extends Component {

  render() {
    return (
      <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <BindScreen />
          </Route>
          <Route path="/success">
            <BindSuccess />
          </Route>
          <Route path="/error">
            <ErrorScreen />
          </Route>
          <Route exact path="/help">
            <Help />
          </Route>
          <Route path="/help/success">
            <HelpSuccess />
          </Route>
          <Route exact path="/consultation/acknowledge/success">
             <ConsultationAcknowledgeSuccess/>
          </Route>
          <Route path="/consultation/change">
            <ConsultationChange/>
          </Route>
        </Switch>
      </div>
      </Router>
    );
  }
}


export default withTranslation()(App);
