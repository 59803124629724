import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ClientApi from '../../services/clientApi';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'react-weui';
import { isValidateEmail, isValidateMobileNumber } from '../../common/util/validation';

import {
  buttonStyle,
  colStyle,
  containerStyle,
  inputStyle,
  textStyle,
  textareaStyle,
} from '../../styles/shared';

import I18n from '../I18n';
import HelpError from '../HelpError';
import Loading from '../Loading';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      emailAddress: '',
      mobileNumber: '',
      nameValidate: '',
      mobileValidate: '',
      emailValidate: '',
      subjectValidate: '',
      textareaValidate: '', 
      nameError: false, 
      textareaError: false,
      subjectError: false,
      mobileError: false,
      emailError: false,
      submitDisable: true,
      questionDescription: '',
    };
    this.specialistNameRef=React.createRef();
    this.emailAddressRef=React.createRef();
    this.mobileNumberRef=React.createRef();
    this.subjectRef=React.createRef();
    this.questionDescriptionRef=React.createRef();
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.mobile && !this.state.mobileError && !this.state.mobileNumber){
      this.setState({mobileError: true});
    }

    if(this.props.name && !this.state.nameError && !this.state.name){
      this.setState({nameError: true});
    }

    if(this.props.email && !this.state.emailError && !this.state.emailAddress){
      this.setState({emailError: true});
    }

    if(this.isValidate() && (prevState.nameError!==this.state.nameError || prevState.mobileError!==this.state.mobileError || prevState.emailError!==this.state.emailError || prevState.subjectError!==this.state.subjectError || prevState.textareaError!==this.state.textareaError)){
      this.setSubmitDisable(false);
    } 

    if(!this.isValidate() && (prevState.nameError!==this.state.nameError || prevState.mobileError!==this.state.mobileError || prevState.emailError!==this.state.emailError || prevState.subjectError!==this.state.subjectError || prevState.textareaError!==this.state.textareaError)){
      this.setSubmitDisable(true);
   }
   }

  specialistNameChange(event) {
    const specialistName = event.target.value;
    const specialistNameLen = event.target.value.length;
    this.setState({
      name: specialistName
    })
    if(specialistNameLen === 0 || specialistNameLen > 50){
      this.setState({
        nameValidate: 'invalidate_name',
        nameError: false,
      });
    }else{
      this.setState({
        nameValidate: '',
        nameError: true,
      });
    }
  }

  mobileNumberChange(event){
    const mobileNumber = event.target.value.trim();
    this.setState({
      mobileNumber: mobileNumber
    });

    if(!isValidateMobileNumber(mobileNumber)){
      this.setState({
        mobileValidate: 'invalidate_mobile',
        mobileError: false,
      });
    }else{
      this.setState({
        mobileValidate: '',
        mobileError: true,
      });
    }
  }
  emailAddressChange(event){
    const emailAddress = event.target.value;
    const emailAddressLen = event.target.value.length
    this.setState({
      emailAddress: event.target.value
    });
    if(!isValidateEmail(emailAddress)){
      this.setState({
        emailValidate: 'invalidate_email',
        emailError: false,
      });
    }else if( emailAddressLen > 50) {
      this.setState({
        emailValidate: 'invalidate_email_length',
        emailError: false,
      });
    }
     else{
      this.setState({
        emailValidate: '',
        emailError: true,
      });
    }
  }

  subjectChange(event) {
    const subject = event.target.value;
    const subjectLen = event.target.value.length;
    this.setState({
      subject:subject
    });

    if (!subject) {
      this.setState({
        subjectValidate: 'invalidate_subject',
        subjectError: false,
      });
    } else if(subjectLen > 100){ 
      this.setState({
        subjectValidate: 'invalidate_subject_length',
        subjectError: false,
      });
    } 
      else {
      this.setState({
        subjectValidate: '',
        subjectError: true,
      });
    }
  }
  
  textareaChange(event) {
    let textLen = event.target.value.length;
    this.setState({
      questionDescription: event.target.value,
    });

    if (textLen > 200 || textLen === 0) {
      this.setState({
        textareaValidate: 'invalidate_textarea',
        textareaError: false,
      });
    } else {
      this.setState({
        textareaValidate: '',
        textareaError: true,
      });
    }
  }

  setSubmitDisable(status) {
    this.setState({
      submitDisable: status,
    });
  }

  isValidate(){
    if(this.state.mobileError && this.state.emailError && this.state.subjectError && this.state.textareaError && this.state.nameError){
      return true;
    }else{
      return false;
    }
  }

  async raiseTicket(e) {
    e.preventDefault();
    try {
      this.props.onSubmit && this.props.onSubmit();
      const client = new ClientApi(this.props.lang);
      const res = await client.createTicket(
        this.props.openId,
        this.props.uid,
        this.specialistNameRef.current.value ?? this.props.name,
        this.emailAddressRef.current.value ?? this.props.email,
        this.mobileNumberRef.current.value ?? this.props.mobile.trim(),
        this.subjectRef.current.value,
        this.questionDescriptionRef.current.value
      );

      if (res.count <= 10 && res.openId === this.props.openId) {
        this.props.onSuccess &&
          this.props.onSuccess({ openId: res.openId, count: res.count });
        return;
      } else {
        throw new Error('internal_server_error');
      }
    } catch (error) {
      this.props.onError && this.props.onError(error.message);
    }
  }

  render() {
    return (
      <Container style={containerStyle}>
        <Row>
          <Col xs={12} style={colStyle}>
            <h5 style={{ fontSize: '18px' }}>
              <I18n code='homepage_header' />
            </h5>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={{ paddingTop: '20px', paddingBottom: '0px' }}></div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='homepage_context' />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={{ paddingTop: '20px', paddingBottom: '0px' }}></div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
              <I18n code='homepage_sub_header' />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='special_name' />{' '}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <input
              style={inputStyle}
              id='special_name'
              className='weui-input'
              autoFocus
              type='text'
              ref={this.specialistNameRef}
              onChange={(e)=>this.specialistNameChange(e)}
              defaultValue={this.props.name || ''}
              disabled={!this.props.nameEnabled}
            />
          </Col>
        </Row>

        <HelpError error={{ data: this.state.nameValidate }} />

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='contact' />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <input
              style={inputStyle}
              id='contact_mobile'
              className='weui-input'
              autoFocus
              type='text'
              ref={this.mobileNumberRef}
              onChange={(e)=>this.mobileNumberChange(e)}
              defaultValue={this.props.mobile || ''}
            />
          </Col>
        </Row>

        <HelpError error={{ data: this.state.mobileValidate }} />

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='email_address' />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <input
              style={inputStyle}
              id='email_address'
              className='weui-input'
              autoFocus
              type='text'
              ref={this.emailAddressRef}
              onChange={(e)=>this.emailAddressChange(e)}
              defaultValue={this.props.email || ''}
            />
          </Col>
        </Row>

        <HelpError error={{ data: this.state.emailValidate }} />

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='subject_title' />{' '}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <input
              style={inputStyle}
              id='subject_title'
              className='weui-input'
              autoFocus
              type='text'
              ref={this.subjectRef}
              onChange={(e) => this.subjectChange(e)}
              value={this.state.subject || ''}
            />
          </Col>
        </Row>

        <HelpError error={{ data: this.state.subjectValidate }} />

        <Row>
          <Col xs={12} style={colStyle}>
            <div style={textStyle}>
              <I18n code='question_description' />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={colStyle}>
            <textarea
              rows='3'
              style={textareaStyle}
              id='question_description'
              ref={this.questionDescriptionRef}
              onChange={(e) => this.textareaChange(e)}
              placeholder={this.props.t('question_description_limit')}
            ></textarea>
          </Col>
        </Row>

        <HelpError error={{ data: this.state.textareaValidate }} />

        <Row>
          <Col xs={12} className='justify-content-end' style={colStyle}>
            {this.props.spinner ? (
              <Loading />
            ) : (
              <Button
                id='pin_request_button'
                disabled={this.state.submitDisable}
                style={buttonStyle}
                onClick={(e) => this.raiseTicket(e)}
              >
                <I18n code='submit_ticket' />
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

Component.propTypes = {
  onProgress: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  lang: PropTypes.string,
  t: PropTypes.func,
  queryDate: PropTypes.object,
  category: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
  name: PropTypes.string,
  openId: PropTypes.string,
  uid: PropTypes.string,
  specialistType: PropTypes.string,
  spinner: PropTypes.bool,
  nameEnabled: PropTypes.bool,
  emailError: PropTypes.bool,
  mobileError: PropTypes.bool,
  mobileValidate: PropTypes.string,
  emailValidate: PropTypes.string
};

export default withTranslation()(Component);
