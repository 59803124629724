import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_URL;

export default class clientApi {

  constructor(lang) {
    return this.setLanguage(lang);
  }

  setLanguage(lang) {
    this.lang = lang
  }

  getLanguageCode() {
    return this.lang;
  }

  async requestHandler(url, input) {
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: input,
      url,
      validateStatus() {
        return true; // prevent exceptions on non HTTP 200 responses
      },
    };

    const res = await axios(options);

    if (res.status >= 400) {
      if (res.data.code) {
        throw new Error(`${res.data.message}`);
      }

      throw new Error(res.url + res.data);
    }

    return res.data;
  }

  async pin(mobile, code) {
    if (!mobile) {
      throw new Error('mobile_required');
    }

    if (mobile.length < 14) {
      throw new Error('mobile_too_short');
    }

    if (!code) {
      throw new Error('code_required');
    }

    return this.requestHandler(`${apiBaseUrl}/pin`, {
      mobile,
      code,
      lang: this.getLanguageCode()
    });
  }

  async verify(pin, pinId) {
    if (!pin) {
      throw new Error('pin_required');
    }

    if (!pinId) {
      throw new Error('pinId_required');
    }

    return this.requestHandler(`${apiBaseUrl}/verify`, { pinCode: parseInt(pin, 10), pinId, lang: this.getLanguageCode() });
  }

  async createTicket(openid, uid, name, email, mobile, subject, description) {

    if (!openid) {
      throw new Error('openid_required');
    }

    if (!name) {
      throw new Error('specialist_name_required');
    }

    if (!email) {
      throw new Error('email_required');
    }

    if (!mobile) {
      throw new Error('mobile_required');
    }

    if (!subject) {
      throw new Error('subject_required');
    }

    
    if (!description) {
      throw new Error('question_description_required');
    }

    return this.requestHandler(`${apiBaseUrl}/ticket`,
        {
          openId : openid,
          uid : uid,
          name: name,
          email: email,
          mobile: mobile,
          subject: subject,
          description: description
        }
        );
  }
}
