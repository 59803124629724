export default {
    zh_CN: {
      translation: {
        'home_message': `欢迎使用Third Bridge微信服务！<br />
        请使用您预留的手机号码进行账户绑定`,
        'mobile_number': '手机号码：',
        'get_pin': '验证码',

        'verify_pin': '提 交',
        'success_message': `欢迎回来！<br />
        恭喜您已完成账户绑定！<br />
        请留意您收到的推送消息，您也可以通过公众号自定义服务进行项目查询、付款进度查询等自助服务。<br />
        Third Bridge竭诚为专家提供一流的服务！`,
        'mobile_placeholder': '',
        'pin_placeholder': '请输入验证码',

        'request_invalid': '系统错误，请联系与您对接的咨询顾问。（错误代码：001）',
        'Network Error': '系统错误，请联系与您对接的咨询顾问。（错误代码：001）',

        'mobile_required': '手机号码不能为空，请重新尝试。（错误代码：002）',
        'mobile_too_short': '手机号码格式有误，请输入11位手机号码。（错误代码：003）',
        'code_required': '系统错误，请联系与您对接的咨询顾问。（错误代码：004）',

    
        'pin_required': '验证码不能为空，请重新输入验证码。（错误代码：005）',
        'pinId_required': '系统错误，请联系与您对接的咨询顾问。（错误代码：006）',

        'mobile_invalid': '手机号码格式有误，请输入11位手机号码。（错误代码：102）',
        
        'mobile_not_found': '手机号码匹配失败，请联系与您对接的咨询顾问。（错误代码：100）',
        'mobile_found_multiple': '手机号码匹配失败，请联系与您对接的咨询顾问。（错误代码：100）',
        'openid_already_used': '手机号码匹配失败，请联系与您对接的咨询顾问。（错误代码：100）',
        'mobile_already_bound': '您已完成微信绑定，无需重复提交。',
        'mobile_bound_different_openid': '手机号码匹配失败，请联系与您对接的咨询顾问。（错误代码：100）',

        'cannot_send_sms': '系统错误，请联系与您对接的咨询顾问。（错误代码：151）',
        'cannot_generate_pin': '系统错误，请联系与您对接的咨询顾问。（错误代码：150）',
        'internal_server_error': '系统错误，请联系与您对接的咨询顾问。（错误代码：199）',

        'pin_not_found': '验证码输入有误，请重新输入或稍后再次尝试获取新的验证码。（错误代码：200）',
        'pin_expired': '验证码已过期，请尝试重新获取验证码。（错误代码：201）',
        'pin_invalid': '验证码输入有误，请重新输入或稍后再次尝试获取新的验证码。（错误代码：202）',
        'cannot_resolve_openid': '系统错误，，请联系与您对接的咨询顾问。（错误代码：203）',

        'internal_error': `Something went wrong<br/>
            Please feel free to contact our specialist <br/>
            manager if you have any questions.`,

        'share_error': '请先关注Thirdbridge官方微信公众号',

        'homepage_header' : '专家客服',
        'homepage_sub_header' : '联系我们',
        'homepage_context' : '如果您有任何问题，请填写以下信息, 我们会尽快处理，感谢您的理解与支持。',
        'special_name' : `姓名 <span style='color: red'>*</span>`,
        'contact' : `联系电话 <span style='color: red'>*</span>`,
        'email_address' : `邮箱地址 <span style='color: red'>*</span>`,
       
        'subject_title' : `主题 <span style='color: red'>*</span>`,
        'question_description' : `描述 <span style='color: red'>*</span>`,
        'question_description_limit' : '不能超过200个字',
        'submit_ticket' : '提交',
        'help_success_message': '感谢您的反馈，我们的客服人员会尽快处理，感谢您的理解与支持。',

        'invalidate_name' : '名字长度不能为空或大于50字',
        'invalidate_email' : '不合法的邮箱',
        'invalidate_email_length' : '邮箱长度不能大于50字',
        'invalidate_mobile' : '不合法的手机号',
        'invalidate_textarea' : '不为空且小于200字',
        'invalidate_question_category' : '分类必须填写',
        'ticket_over_max_limit' : '提交的次数过多',
        'invalidate_subject' : '主题不能为空',
        'invalidate_subject_length' : '主题字数不能大于100字',
        'raise_error' : '系统错误',
        'invalid_email_for_fresh_desk' : '请检查邮箱是否正确',


        'acknowledage_success_header' : '感谢您的确认!',
        'acknowledage_success_message' : `感谢您的确认! <br>
        如有任何疑问，请联系与您对接的咨询顾问。<br>
        Third Bridge 高临咨询竭诚为您服务`,

        'acknowledage_cancel_update_message' : `非常抱歉，当前会议可能已变更或者已取消，请留意后续通知。<br>
        如有任何疑问，请联系与您对接的咨询顾问。<br>
        Third Bridge 高临咨询竭诚为您服务`

      },
    },
    en: {
      translation: {
        'home_message': `Thanks for following our Third Bridge Official WeChat Account. Please use your mobile number to finish account binding.`,
        'mobile_number': 'Mobile Number:',
        'get_pin': 'Get PIN',
        'verify_pin': 'Submit',
        'success_message': `Welcome back!<br />
        Thanks for finishing account binding!<br />
        You will receive project notifications from this channel and feel free to use the menu for self service.<br />
        Third Bridge Team`,
        'mobile_placeholder': 'Type mobile number code here',
        'pin_placeholder': 'Type verification code here',

        'request_invalid': 'System Error, please contact your researcher. (code: 001)',
        'Network Error': 'System Error, please contact your researcher. (code: 001)',

        'mobile_required': 'Mobile number cannot be empty, please enter a valid number. (code: 002)',
        'mobile_too_short': 'Mobile number is invalid, please enter a valid number. (code: 102)',
        'code_required': 'System Error, please contact your researcher. (code: 004)',

    
        'pin_required': 'PIN code cannot be empty, please enter a valid PIN. (code: 005)',
        'pinId_required': 'System Error, please contact your researcher. (code: 006)',

        'mobile_invalid': 'Mobile number is invalid, please enter a valid number. (code: 102)',
        
        'mobile_not_found': 'Binding failed with mobile number, please contact your researcher. (code: 100)',
        'mobile_found_multiple': 'Binding failed with mobile number, please contact your researcher. (code: 100)',
        'openid_already_used': 'Binding failed with mobile number, please contact your researcher. (code: 100)',
        'mobile_already_bound': 'You have already accomplished binding, please do not try again.',
        'mobile_bound_different_openid': 'Binding failed with mobile number, please contact your researcher. (code: 100)',

        'cannot_send_sms': 'System Error, please contact your researcher. (code: 151)',
        'cannot_generate_pin': 'System Error, please contact your researcher. (code: 150)',
        'internal_server_error': 'System Error, please contact your researcher. (code: 199)',

        'pin_not_found': 'PIN code is invalid, please try again or generate a new PIN code. (code: 200)',
        'pin_expired': 'PIN code has expired, please generate a new PIN code. (code: 201)',
        'pin_invalid': 'PIN code is invalid, please try again or generate a new PIN code. (code: 202)',
        'cannot_resolve_openid': 'System Error, please contact your researcher. (code: 203)',

        'internal_error': `Something went wrong<br/>
         Please feel free to contact our specialist <br/>
         manager if you have any questions.`,

        'share_error': 'Please following Third Bridge Official WeChat Account',

        'homepage_header' : 'Specialist Service',
        'homepage_sub_header' : 'Contact Us',
        'homepage_context' : 'If you have any question, please fill with the following information, We will deal with it as soon as possible. Thank you for your understanding and support.',
        'special_name' : `Name <span style='color: red'>*</span>`,
        'contact' :  `Mobile Number <span style='color: red'>*</span>`,
        'email_address' : `Email Address <span style='color: red'>*</span>`,
  
        'invalidate_name' : 'Name should not be null or more than 50 words',
        'subject_title' : `Subject <span style='color: red'>*</span>`,
        'question_description' : `Description <span style='color: red'>*</span>`,
        'question_description_limit' : 'Cannot exceed 200 words',
        'submit_ticket' : 'Submit',
        'help_success_message': 'Thank you for your feedback. Our customer service staff will handle it as soon as possible. Again, thank you for your understanding and support. ',
        'invalidate_email' : 'Invalidate Email',
        'invalidate_email_length' : 'emaill address less than 50 words',
        'invalidate_mobile' : 'Invalidate Phone',
        'invalidate_textarea' : 'Not empty and less than 200 words',
        'invalidate_question_category' : 'Category is required',
        'ticket_over_max_limit' : 'Raise too many times',
        'invalidate_subject' : 'Subject is required',
        'invalidate_subject_length' : 'Subject less than 100 words',
        'raise_error' : 'internal sever error',
        'invalid_email_for_fresh_desk' : 'Please check whether the email address is correct',

        'acknowledage_success_message' : `Thanks for your confirmation.</br>
        Please feel free to contact our specialist manager if you have any questions</br>
        Third Bridge Team`,

        'acknowledage_cancel_update_message' : `We are so sorry, the call may be rescheduled or cancelled. Please pay attention to the following notification.</br>
        Please feel free to contact our specialist manager if you have any questions.</br>
        Third Bridge Team`
      }
    }
  };
