import React from 'react';
import PropTypes from 'prop-types';
import { textErrorStyle } from '../../styles/shared';

import I18n from '../I18n';

class Component extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div>
        { (this.props.error && this.props.error.data) ? (
          <div style={textErrorStyle} id="error_message">
            <I18n code={this.props.error.data.message} />
          </div>
        ) 
        : 
        null }
      </div>
    );
  }
}

Component.propTypes = {
  error: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default Component;
