import React from 'react';

class Component extends React.Component  {
  render() {

    return (
      <svg data-name="Pic" viewBox="0 0 97 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>Image</title>
          <desc>Created with Sketch.</desc>
          <g id="WeChat-binding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="1)-Input-Phone-Number-Disabled-Button" transform="translate(-174.000000, -150.000000)">
                  <g id="Image" transform="translate(174.000000, 150.000000)">
                      <g id="smartphone">
                          <path d="M65.03925,0 L5.96075,0 C2.774,0 0.18975,2.58425 0.18975,5.771 L0.18975,44.138 L0.18975,61.79325 L0.18975,122.229 C0.18975,125.41575 2.774,128 5.96075,128 L65.0415,128 C68.226,128 70.81025,125.41575 70.81025,122.229 L70.81025,61.793 L70.81025,44.13775 L70.81025,5.771 C70.81025,2.58425 68.226,0 65.03925,0 Z" id="Path" fill="#3E5B7C"></path>
                          <rect id="Rectangle" fill="#F4F7FB" x="0.18975" y="13.2415" width="70.62075" height="92.68975"></rect>
                          <g id="Group" transform="translate(22.250000, 4.250000)" fill="#C4CED3" fillRule="nonzero">
                              <path d="M24.2845,4.5775 L2.2155,4.5775 C0.99725,4.5775 0.0085,3.58875 0.0085,2.3705 C0.0085,1.15225 0.99725,0.1635 2.2155,0.1635 L24.2845,0.1635 C25.50275,0.1635 26.4915,1.15225 26.4915,2.3705 C26.4915,3.58875 25.50275,4.5775 24.2845,4.5775 Z" id="Path"></path>
                              <path d="M39.73275,4.5775 L37.52575,4.5775 C36.3075,4.5775 35.31875,3.58875 35.31875,2.3705 C35.31875,1.15225 36.3075,0.1635 37.52575,0.1635 L39.73275,0.1635 C40.951,0.1635 41.93975,1.15225 41.93975,2.3705 C41.93975,3.58875 40.951,4.5775 39.73275,4.5775 Z" id="Path"></path>
                              <path d="M15.98875,117.12925 L10.509,117.12925 C8.366,117.12925 6.62925,115.3925 6.62925,113.24725 L6.62925,112.18125 C6.62925,110.03825 8.366,108.3015 10.51125,108.3015 L15.991,108.3015 C18.134,108.3015 19.87075,110.03825 19.87075,112.1835 L19.87075,113.2495 C19.87075,115.3925 18.13375,117.12925 15.98875,117.12925 Z" id="Path"></path>
                          </g>
                      </g>
                      <g id="speak" transform="translate(26.000000, 7.000000)">
                          <path d="M48.8830683,0 L22.1169317,0 C9.9019762,0 0,9.99495652 0,22.3246114 C0,31.2435619 5.1822031,38.9406923 12.6724041,42.516485 C15.6802737,43.9528357 17.6096937,46.9981675 17.6096937,50.3569154 L17.6096937,55.1461477 C17.6096937,56.9671456 19.9409378,57.692863 20.956291,56.1886335 L26.1957789,48.4320043 C27.7947732,46.0654614 30.4489712,44.6492228 33.2866465,44.6492228 L48.8830683,44.6492228 C61.098024,44.6492228 71,34.6542663 71,22.3246114 C70.9991698,9.99495652 61.0971936,0 48.8830683,0 Z" id="Path" fill="#C8E2FF"></path>
                          <path d="M21.4221619,42.516485 C13.8262358,38.9406923 8.57088554,31.2435619 8.57088554,22.3246114 C8.57088554,9.99495652 18.6126289,0 31,0 L22.4291145,0 C10.0417436,0 0,9.99495652 0,22.3246114 C0,31.2435619 5.25450854,38.9406923 12.8504345,42.516485 C15.9016023,43.9528357 17.8574144,46.9981675 17.8574144,50.3569154 L17.8574144,55.1461477 C17.8574144,56.9671456 20.2215643,57.692863 21.2520912,56.1886335 L26.2876968,48.8367634 C25.7960075,46.1014957 24.0035306,43.7316008 21.4221619,42.516485 L21.4221619,42.516485 Z" id="Path" fill="#A9D1FF"></path>
                          <path d="M25,23 C25,25.209028 23.2096594,27 21.0004066,27 C18.7911541,27 17,25.209028 17,23 C17,20.7909718 18.7911541,19 21.0004066,19 C23.2096594,19 25,20.7909718 25,23 Z" id="Path" fill="#4A90E2"></path>
                          <path d="M40,23 C40,25.209028 38.2090282,27 36,27 C33.790972,27 32,25.209028 32,23 C32,20.7909718 33.790972,19 36,19 C38.2090282,19 40,20.7909718 40,23 Z" id="Path" fill="#4A90E2"></path>
                          <path d="M55,23 C55,25.209028 53.2090282,27 51,27 C48.790972,27 47,25.209028 47,23 C47,20.7909718 48.790972,19 51,19 C53.2090282,19 55,20.7909718 55,23 Z" id="Path" fill="#4A90E2"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}

export default Component;
