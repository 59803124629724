import React from 'react';
import PropTypes from 'prop-types';
import {colStyle, containerScreenStyle, logoStyle, spaceStyle, textErrorStyle} from '../../styles/shared';
import querystring from 'query-string';
import imgURL from '../../qrcode.jpg';

import I18n from '../I18n';
import i18n from '../../i18n';
import Logo from '../Logo';
import {Col, Container, Row} from 'react-bootstrap';

class Component extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
        errorType : '',
    };
  }

  componentDidMount(){
    const qs = querystring.parse(document.location.hash.split('?')[1]);
    let type = qs.type ? qs.type : 'internal_error' ; 
    this.setState({ errorType : type});
    let language = qs.lang ? qs.lang : 'en';
    i18n.changeLanguage(language);
    document.location.href = '#/error';
  }

  render() {
    let shareError = this.state.errorType === 'share_error' ? (<img src={imgURL} alt='' style={{ width:'100%' }}/>) : '';

    return (
        <Container style={containerScreenStyle} fluid='md'>

        <div style={logoStyle}>
          <Logo />
        </div>

          <Row>
            <Col xs={12} style={colStyle}>
              <div style={spaceStyle}></div>
            </Col>
          </Row>

        <div style={textErrorStyle} id='error_message'>
          <I18n code={this.state.errorType} />
          {shareError}
        </div>

        </Container>

    );
  }
}

Component.propTypes = {
  t: PropTypes.func,
};

export default Component;
