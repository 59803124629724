import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources from './i18n-resources';

const detectOptions = {
    order: ['querystring', 'localStorage', 'navigator'],
    caches: ['localStorage'],

    lookupQuerystring: 'lang',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    detection: detectOptions,
    fallbackLng: ['en'],
    debug: false,
    load: 'all',
    preload: ['zh_CN', 'en'],

    backend: {
      allowMultiLoading: true
    },
  });


export default i18n;
