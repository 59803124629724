export const containerStyle = {
  paddingTop: '10px',
  paddingLeft: '0px',
  paddingRight: '0px',
};

export const inputStyle = {
  color: 'black',
  fontFamily: 'Arial',
  fontSize: '16px',
  width: '100%',
  height: '37px',
  backgroundColor: 'rgb(244, 247, 251)',
  paddingLeft: '10px',
  borderRadius: '2px',
};

export const buttonStyle = {
  fontFamily: 'Arial',
  fontSize: '0.8em',
  width: '100%',
  height: '37px',
  borderRadius: '2px',
  // background: "rgb(106, 150, 201)"
};

export const textStyle = {
  display: 'inline',
  fontFamily: 'Arial',
  fontSize: '14px',
  textAlign: 'left',
  paddingTop: '0.5px',
  lineHeight: '40px'
};


export const containerScreenStyle = {
  color: 'black',
  fontFamily: 'Arial',
  padding: '30px',
};

export const pinButtonContainerStyle = {
  marginTop: '0px',
};

export const picStyle = {
  width: '80px',
  margin: 'auto',
  padding: '0',
  paddingTop: '30px',
  paddingBottom: '30px',
  textAlign: 'center',

};

export const logoStyle = {
  width: '120px',
  margin: '0',
  padding: '0',
  textAlign: 'left',
};

export const textErrorStyle = {
  fontFamily: 'Arial',
  fontSize: '0.9em',
  lineHeight: '40px',
  color: 'red',
  padding: '10px',
};

export const colStyle = {
  padding: '3px',
};

export const textareaStyle = {
  color: 'black',
  width: '100%',
  fontFamily: 'Arial',
  backgroundColor: 'rgb(244, 247, 251)',
  paddingLeft: '10px',
  border:'0px',
  borderRadius: '2px',
  fontSize: '16px',
  outline:'none',
  padding: '10px'
}

export const spaceStyle = {
  paddingTop: "40px",
  paddingBottom: "0px"
}

export const iconStyle = {
  padding: "20px",
  fontSize: "3em"
}
