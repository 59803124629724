import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import {textStyle, containerScreenStyle, colStyle, logoStyle, spaceStyle} from '../../styles/shared';
import Logo from '../Logo'

import I18n from '../I18n';


class Component extends React.Component  {

    render() {

        return (
            <Container style={containerScreenStyle} fluid='md'>

                <div style={logoStyle}>
                    <Logo />
                </div>
                <Row>
                    <Col xs={12 } style={colStyle}>
                        <div style={spaceStyle}></div>
                    </Col>
                </Row>
                <div id='container'>
                    <Row>
                        <Col xs={12} style={colStyle}>
                             <I18n code='homepage_header' />
                        </Col>
                    </Row>
                <div style={textStyle}>
                    <I18n code='help_success_message' />
                </div>
                </div>

            </Container>
        );
    }
}

export default Component;
