import React from 'react';
import { Container } from 'react-bootstrap';

import { textStyle, containerScreenStyle, picStyle } from '../../styles/shared';
import Pic from '../Pic'

import I18n from '../I18n';

class Component extends React.Component  {

  render() {

    return (
      <Container style={containerScreenStyle} fluid='md'>

      <div style={picStyle}>
        <Pic />
      </div>

      <div style={textStyle}>
        <I18n code='success_message' />
      </div>

      </Container>
    );
  }
}

export default Component;
