import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import querystring from 'query-string';
import { Container } from 'react-bootstrap';

import { containerScreenStyle, picStyle } from '../../styles/shared';

import Loading from '../Loading';
import Pic from '../Pic';
import FormMobile from '../FormMobile';
import FormPin from '../FormPin';
import BindError from '../BindError';
import i18n from '../../i18n.js';

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileResult: null,
      mobileError: null,
      pinResult: null,
      pinError: null,
      pinId: null,
      oauthCode: null,
      spinner: null,
    };
  }

  componentDidMount() {
    const qs = querystring.parse(document.location.search || this.props.qs);
    this.setState({ lang: i18n.language });

    if (qs.code) {
      localStorage.setItem('oauthCode', qs.code);
      document.location.href = '/';
      return;
    } else {
      let lsCode = localStorage.getItem('oauthCode');
      this.setState({ oauthCode: lsCode });

      if (!lsCode) {
        document.location.href = '#/error?type=share_error&lang=en';
        return;
      }
    }
  }

  render() {
    return (
      <Container style={containerScreenStyle} fluid='md'>
        <div style={picStyle}>
          <Pic />
        </div>

        <div id='container'>
          <div style={{ width: '28px', height: '28px', margin: 'auto' }}>
            {this.state.spinner ? <Loading /> : null}
          </div>

          <FormMobile
            oauthCode={this.state.oauthCode}
            lang={this.state.lang}
            onSubmit={() => {
              this.setState({
                mobileResult: null,
                mobileError: null,
                pinId: null,
                pinError: null,
                spinner: true,
              });
            }}
            onTimeOut={() => {
              this.setState({
                mobileResult: null,
                mobileError: null,
                pinError: null,
                screenState: null,
                spinner: false,
              });
            }}
            onProgress={() => {
              this.setState({
                mobileError: null,
              });
            }}
            onSuccess={(data) => {
              this.setState({
                mobileResult: data,
                mobileError: null,
                pinId: data.pinId,
                pinError: null,
                screenState: 'pin',
                spinner: false,
              });
            }}
            onError={(error) => {
              this.setState({
                mobileResult: null,
                mobileError: error,
                pinError: null,
                spinner: false,
              });
            }}
          />
          <BindError error={{ data: this.state.mobileError }} />

          <FormPin
            pinId={this.state.pinId}
            lang={this.state.lang}
            disabled={this.state.screenState !== 'pin'}
            onSubmit={() => {
              this.setState({
                spinner: true,
              });
            }}
            onSuccess={(data) => {
              this.setState({
                pinResult: data,
                pinError: null,
                spinner: false,
              });

              this.props.history.push('/success');
            }}
            onProgress={() => {
              this.setState({
                pinError: null,
              });
            }}
            onError={(error) => {
              this.setState({
                pinResult: null,
                pinError: error,
                screenState: 'pin',
                spinner: false,
              });
            }}
          />

          <BindError error={{ data: this.state.pinError }} />
        </div>
      </Container>
    );
  }
}

Component.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
  qs: PropTypes.string,
};

export default withRouter(Component);
