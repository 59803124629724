import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'react-weui';

import ClientApi from '../../services/clientApi';
import { colStyle, containerStyle, inputStyle, buttonStyle, pinButtonContainerStyle } from '../../styles/shared';

import I18n from '../I18n';

class Component extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      pin: ''
    };
  }

  pinChange(e) {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^0-9]/g,'');
    this.setState({pin: newValue});
    this.props.onProgress && this.props.onProgress();
  }

  async verifyRequest(e) {
    e.preventDefault();

    try {

      this.props.onSubmit && this.props.onSubmit( { pin: this.state.pin } );
  
      const client = new ClientApi( this.props.lang );
      const res = await client.verify( this.state.pin, this.props.pinId );

      if (res.specialistId) {
        this.props.onSuccess && this.props.onSuccess( { specialistId: res.specialistId });
        return;
      }

      throw new Error('request_failed');

    } catch (error) {
        this.props.onError && this.props.onError( error );
    }

  }
  
  render() {

    return (
        <Container style={containerStyle}>

          <Row>
            <Col xs={12} style={colStyle}>
              <div style={{ paddingTop: '5px'}}></div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} style={colStyle}>
              <input style={inputStyle} id='pin_input' className='weui-input' autoFocus type='text' placeholder={this.props.t('pin_placeholder')} value={this.state.pin}  onChange={(e) => this.pinChange(e)} maxLength='6' disabled={this.props.disabled} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} style={colStyle}>
              <div style={pinButtonContainerStyle}><Button id='pin_verify_button' style={buttonStyle} disabled={this.props.disabled} onClick={(e) => this.verifyRequest(e)}><I18n code='verify_pin' /></Button></div>
            </Col>
          </Row>

        </Container>
    );
  }
}

Component.propTypes = {
  pinId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  t: PropTypes.func,
  lang: PropTypes.string,
};

export default withTranslation()(Component);
